import React from "react";
import { graphql } from "gatsby";
import Paper from "@material-ui/core/Paper";


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <>
      <Paper elevation={3} style={{ padding: "20px", marginBottom: "30px" }}>
        <article className="prose">
          <h2>{frontmatter.title}</h2>
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

        </article>
      </Paper>
    </>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
